<template>
  <v-container>
    <v-toolbar flat dense>
      <v-toolbar-title>
        <v-tabs v-model="tab">
          <v-tab key="info">Информация</v-tab>
          <v-tab key="contract">Договора</v-tab>
        </v-tabs>
      </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn small dark color="primary" v-if="tab===1" @click.stop="addContract">Добавить</v-btn>
      <v-btn icon @click="close"><v-icon>mdi-window-close</v-icon></v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-tabs-items v-model="tab">
      <v-tab-item key="info">
        <v-card flat>
          <v-card-title class="d-flex justify-space-between">
            <span>Карточка контрагента</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :value="getPartnerTypeText(partner.type)"
                  label="Форма собственности"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field :value="partner.name" label="Название" readonly></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="partner.name_additional" label="Дополнительное название" readonly></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-if="partner.type === 'entity'"
                  :value="`${partner.org.legal_form.name} ${partner.org.name}`"
                  label="Контрагент Юридическое лицо"
                  readonly
                ></v-text-field>
                <v-text-field
                  v-if="partner.type === 'individual'"
                  :value="partner.user.full_name"
                  label="Контрагент Физическое лицо"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="partner.type_partner"
                  label="Тип контрагента"
                  multiple
                  dense
                  chips
                  deletable-chips
                  :items="type_partners"
                  item-value="id"
                  item-text="name"
                  readonly
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-simple-table>
                  <thead>
                  <tr>
                    <th></th>
                    <th width="45%">Контактные лица</th>
                    <th width="55%">Роль</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, idx) in partner.contact_person" :key="idx">
                    <td>{{ `${idx + 1}.` }}</td>
                    <td>
                      <span class="d-flex justify-space-between align-center">
                        <v-text-field
                          :value="item.member.full_name"
                          readonly
                        ></v-text-field>
                      </span>
                    </td>
                    <td>
                      <v-text-field :value="item.role" readonly/>
                    </td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-switch
                  v-model="partner.is_active"
                  label="Активен"
                  readonly
                ></v-switch>
              </v-col>
              <v-col cols="3">
                <v-switch
                  v-model="partner.debtor"
                  label="Дебитор"
                  readonly
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="flatTable"
                  :items="partner.service_object"
                  item-key="id"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :footer-props="footerProps"
                >
                  <template v-slot:item.flat.type="{ item }">
                    <span>{{ flatType[item.flat.type] }}</span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
        </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="contract">
        <v-card>
          <v-data-table
            :headers="contractHeader"
            :items="partner.contract"
            :footer-props="footerProps"
            @click:row="rowContractClick"
          ></v-data-table>
        </v-card>
        <v-card v-if="showCard" class="my-3">
          <v-card-title>{{ cardData.name }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-list class="view-list v13">
              <v-list-item>
                <v-list-item-content>Номер договора</v-list-item-content>
                <v-list-item-content>{{ cardData.number }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Дата заключения</v-list-item-content>
                <v-list-item-content>{{ formatDt(cardData.conclusion_dt) }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Дата начала действия</v-list-item-content>
                <v-list-item-content>{{ formatDt(cardData.validity_dt) }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Дата окончания</v-list-item-content>
                <v-list-item-content>{{ formatDt(cardData.expiration_dt) }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Описание</v-list-item-content>
                <v-list-item-content>{{ cardData.description }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Файл</v-list-item-content>
                <v-list-item-content>
                  <div class="d-flex justify-start align-center">
                    {{ cardData.file_real_name }}
                    <v-btn class="ml-3" icon @click="downloadContract(cardData.guid)"><v-icon>mdi-download</v-icon></v-btn>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
      v-model="contractDialog"
      max-width="560px"
    >
      <v-card>
        <v-card-title>Добавить договор</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="contract_form" v-model="validContract" lazy-validation>
            <v-text-field v-model="dataContract.number" label="Номер договора" :rules="rules.required"></v-text-field>
            <v-text-field v-model="dataContract.name" label="Название договора" :rules="rules.required"></v-text-field>
            <date-picker v-model="dataContract.conclusion_dt" label="Дата заключения договора" :rules="rules.required"></date-picker>
            <date-picker v-model="dataContract.validity_dt" label="Дата начала действия договора" :rules="rules.required"></date-picker>
            <date-picker v-model="dataContract.expiration_dt" label="Дата окончания договора" :rules="rules.required"></date-picker>
            <v-file-input v-model="dataContract.file" label="Файл" :rules="rules.required"></v-file-input>
            <v-textarea v-model="dataContract.description" label="Описание"></v-textarea>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="error" small dark class="mr3" @click.stop="closeContract">Отмена</v-btn>
          <v-btn color="success" small dark @click.stop="saveContract">Добавить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DatePicker from '../DatePicker'
export default {
  name: 'PartnerView',
  components: { DatePicker },
  data: function () {
    return {
      tab: null,
      prior_comm: [
        { value: 'email', text: 'E-Mail' },
        { value: 'phone', text: 'Телефон' }
      ],
      partner: {},
      partnerType: [
        { value: 'entity', text: 'Юридическое лицо' },
        { value: 'individual', text: 'Физическое лицо' }
      ],
      flatTable: [
        { text: 'Адрес', value: 'facility.address' },
        { text: 'Тип', value: 'flat.type' },
        { text: 'Номер', value: 'flat.name' }
      ],
      flatType: {
        flat: 'Квартира',
        not_residential: 'Нежилое',
        office: 'Офис',
        parking_place: 'Машиноместо',
        mop: 'МОП'
      },
      contractHeader: [
        { text: 'Номер', value: 'number' },
        { text: 'Название', value: 'name' }
      ],
      sortBy: 'flat.name',
      sortDesc: false,
      type_partners: [],
      footerProps: {
        itemsPerPageOptions: [5, 10, 15]
      },
      showCard: false,
      cardData: {},
      contractDialog: false,
      dataContract: {},
      validContract: true,
      rules: {
        required: [v => !!v || 'Обязательное поле']
      }
    }
  },
  mounted () {
    this.getData()
  },
  computed: {
    partnerId: function () {
      return this.$store.state.rn.params.id
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('partners/GET_ORG_LIST').then(response => {
        this.type_partners = response.type_partners
      })
      this.$store.dispatch('partners/GET_PARTNER_FORM', this.partnerId).then(response => {
        this.partner = response
      })
    },
    formatDt (value) {
      return value ? this.$moment(value).format('DD.MM.YYYY') : ''
    },
    close () {
      this.$store.commit('rn/CLOSE_VIEW')
    },
    getPartnerTypeText (type) {
      const t = this.partnerType.find(item => item.value === type)
      if (t) {
        return t.text
      } else {
        return ''
      }
    },
    rowContractClick (item) {
      this.cardData = { ...item }
      this.showCard = true
    },
    addContract () {
      this.contractDialog = true
    },
    saveContract () {
      if (!this.$refs.contract_form.validate()) return
      const formData = new FormData()
      Object.entries(this.dataContract).forEach(([key, value]) => {
        formData.append(key, value)
      })
      this.$store.dispatch('partners/POST_CONTRACT', { id: this.partnerId, formData: formData }).then(response => {
        this.partner = response
      }).finally(() => {
        this.closeContract()
      })
    },
    closeContract () {
      this.$refs.contract_form.resetValidation()
      this.dataContract = {}
      this.contractDialog = false
    },
    downloadContract (guid) {
      this.$store.dispatch('partners/GET_CONTRACT_FILE', { id: this.partnerId, guid: guid }).then(response => {
        const headers = response.headers
        const blob = new Blob([response.data], { type: headers['content-type'] })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = this.cardData.file_real_name
        link.click()
      })
    }
  },
  watch: {
    partnerId: function () {
      this.getData()
    }
  }
}
</script>

<style scoped>

</style>
